import React from 'react'


function Home() {
  return (
            <div class="main-text-div p-5">
            <h1 class="display-1 card-head ani">Industrial Engineering</h1>
          </div>
  )
}

export default Home
